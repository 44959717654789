import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';

import {
    Box,
    Heading,
    useToast,
    Button,
    FormControl,
    FormLabel,
    Input,
    useColorModeValue,
    Center,
    VStack,
    Text,
    Stack,
    SimpleGrid,
    Container,
    Skeleton,
    Progress,
} from "@chakra-ui/react"

import Map from 'react-map-gl';
import { Source, Layer } from 'react-map-gl';

import useAxios from "../../utils/useAxios";

import { Field } from '../../types/fieldInterface';
import { IDevice } from '../DeviceMap';
import { RenderDevices } from '../DeviceMap';
import { Gradient } from '../DeviceMap';
import authContext from '../../context/AuthContext';



function RenderPolygon({field } : {field: Field}) {
    return (
      <Source
        id="polygon"
        type="geojson"
        data={{
          type: 'FeatureCollection',
          features: Object.values(field.area).map(area => ({
            ...area,
            type: 'Feature',
            geometry: {
              ...area.geometry,
              type: 'Polygon', // or 'MultiPolygon', etc., depending on the actual type
            },
          })),
        }}
      >
        <Layer
          id="polygon"
          type="line"
          paint={{
            'line-color': '#088',
            'line-width': 2,
          }}
        />
        <Layer
          id="polygon-fill"
          type="fill"
          paint={{
            'fill-color': '#088',
            'fill-opacity': 0.2,
          }}
        />
      </Source>
    );
  }

const FieldMap = ({field_id } : {field_id: number}) => {
    // This will be the base-map for the field.
    // It will also control the overlay options for the field like:
    // - Field boundary
    // - Sensors
    // - And more in the future

    const { userSettings } = useContext(authContext);

    const toast = useToast();
    const api = useAxios();

    const [field, setField] = useState<Field | null>(null);
    const [devices, setDevices] = useState<IDevice[]>([]);

    const [isLoadingMap, setIsLoadingMap] = useState(true);
    const [isLoadingDevices, setIsLoadingDevices] = useState(true);

    const [initialViewState, setInitialViewState] = useState({
        latitude: 37.7749,
        longitude: -122.4194,
        zoom: 10,
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(`agtech/fields/${field_id}/`);
                setField(response.data);
                console.log(response.data);
                // Set the initial view state of the map
                if (response.data.field_center_lat && response.data.field_center_lon) {
                    console.log("Setting initial view state")
                    setInitialViewState({
                        latitude: response.data.field_center_lat,
                        longitude: response.data.field_center_lon,
                        zoom: 15,
                    });
                }

            } catch (error: any) {
                setField(null);
                // Toast
                toast({
                    title: "Error fetching data",
                    status: "error",
                    description: error.response.data,
                    duration: 9000,
                    isClosable: true,
                });
            }
        };
        const fetchDevices = async () => {
            try {
                const response = await api.get("frontend/devices/current-data/");
                console.log(response.data);
                setDevices(response.data);

                setIsLoadingDevices(false);
            } catch (error: any) {
                if (error.response.status === 401) {
                    // Currently, this is handled elsewhere
                    return;
                }
                setDevices([]);
                setIsLoadingDevices(false);
                // Toast
                toast({
                    title: "Error fetching data",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        };

        fetchData();
        fetchDevices();
    }, []);

    useEffect(() => {
        if (field) {
            setIsLoadingMap(false);
            console.log(field.area)
        }
    }, [field]);
 
    return (
        <>
        {isLoadingMap ? (
            <Skeleton isLoaded={false}>
            <Box width="100%" height={{ base: 400, md: 400, lg: 600 }}>
                Loading map...
            </Box>
            </Skeleton>
        ) : (
        <Box width="100%" height={{ base: 400, md: 400, lg: 600 }}  display="flex" flexDirection="column">
            <Map
                initialViewState={initialViewState}
                mapStyle="mapbox://styles/mapbox/satellite-v9"
                mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                style={{ width: "100%", height: "100%" }}
                mapLib={import('mapbox-gl')}

            >
    {!!field && <RenderPolygon field={field} />}
    {devices.length > 0 && <RenderDevices devices={devices} />}
            </Map>
            <Center>

                <Box width={{ base: "90%", md: "50%" }} mt={4}>
                { isLoadingDevices ? (
                    <>
                        <Progress size="xs" isIndeterminate />
                        <Text textAlign={"center"}>Loading device data...</Text>
                    </>
                ) : (
                    <Gradient
                        min={userSettings.moisture_plot_minimum ? userSettings.moisture_plot_minimum : 10}
                        max={userSettings.moisture_plot_maximum ? userSettings.moisture_plot_maximum : 50}
                    />
                    )}
                    </Box>
            </Center>
        </Box>
    )}
    </>
    )
}

export default FieldMap;